import "./App.css";
import { useState } from "react";
import { pushIdea } from "./db";
import styled from "styled-components";

const Input = styled.input`
  border: 1px solid #888;
  outline: none;
  border-radius: 4px;
  max-width: 400px;
  width: 80%;
  padding: 8px;
  margin: 20px;
  box-shadow: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  font-size: 16px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
`;

const Submit = styled.button`
  background-color: #0269a4;
  width: 120px;
  height: 40px;
  outline: none;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-weight: bold;
  color: white;
  border: none;
  border-radius: 4px;
  :hover {
    opacity: 0.9;
  }
`;

function App() {
  const [idea, setIdea] = useState("");

  return (
    <div className="App">
      <Input value={idea} onChange={(e) => setIdea(e.target.value)} />
      <br />
      <Submit
        onClick={() => {
          pushIdea(idea);
          setIdea("");
        }}
      >
        Submit
      </Submit>
    </div>
  );
}

export default App;
